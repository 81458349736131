import DeepDiveContentSectionHeader from '../DeepDiveContentSectionHeader/DeepDiveContentSectionHeader';
import DeepDiveContentCard from '../DeepDiveContentCard/DeepDiveContentCard';

const defaultDeepDiveContent = {
  contentCards: [
    {
      subtitle: 'The creative take',
      title: `Bot listeners and followers 👎`,
      textContent: `We’re presented with shortcuts every day, and music marketing is no stranger to companies offering a quick fix to “juice” the numbers.`,
      authorName: 'Dan Fratoni',
      authorAlt: 'Dan Fratoni, Chief Artist Officer, Musicfox, Inc.',
      authorImgSrc: '/images/dan.jpg',
      imgSrc:
        'https://cdn.sanity.io/images/8du83upr/production/47ff032270786444382f140c0300e7e9598e1658-2250x1500.png?rect=0,187,2250,1125&w=2000&h=1000',
      imgAlt: `A robot listener's hand starts to pick the some artist's pocket.`,
      date: 'Sep 27, 2021',
      readingTimeText: '4 min read',
      href: 'https://blog.b00st.com/blog/bot-listeners-and-followers',
      id: 'post1',
    },
    {
      subtitle: 'Aboveboard advertising',
      title: `FTC Influencer-Advertiser Crackdown`,
      textContent: `Do you get paid via product, brand, or affiliate endorsement? The Federal Trade Commission sent letters to more than seven hundred major companies, warning them and their influencer marketing partners to either disclose relationships or risk seriously breaking US law.`,
      authorName: 'Jason R. Stevens, CFA',
      authorAlt: 'Jason R. Stevens, CFA, CTO + CFO, Musicfox, Inc.',
      authorImgSrc:
        'https://res.cloudinary.com/musicfox/image/upload/c_scale,q_56,w_332/v1606849494/musicfox.io/jason_headshot_fybx6x.jpg',
      imgSrc:
        'https://res.cloudinary.com/tincre/image/upload/v1638654157/blog.b00st.com/ftc-influencer-advertiser-crackdown_ho3s1b_n9vxzd_isuh1u.webp',
      imgAlt: `Two hands exchange cash in the sepia-toned shadows.`,
      date: 'Oct 14, 2021',
      readingTimeText: '6 min read',
      href: 'https://blog.b00st.com/blog/ftc-influenceradvertiser-crackdown',
      id: 'post2',
    },
    {
      subtitle: 'Better consumption of your music',
      title: 'The Right Fans Matter',
      textContent: `Many artists try a multitude of strategies that lead to skewed data on their platforms. Trying to garner numbers at the cost of a scattered audience makes finding the core fan a frustrating task.`,
      authorImgSrc: '/images/dan.jpg',
      authorName: 'Dan Fratoni',
      authorAlt: 'Dan Fratoni, Chief Artist Officer, Musicfox, Inc.',
      imgSrc:
        'https://res.cloudinary.com/tincre/image/upload/v1638648269/blog.b00st.com/the-right-fans-matter_q4ckgo_fpziaf.webp',
      imgAlt:
        'Seashells are arranged on a white background demonstrating the beauty and power of order.',
      date: 'November 11, 2021',
      readingTimeText: '2 min read',
      href: 'https://blog.b00st.com/blog/the-right-fans-matter',
      id: 'post3',
    },
  ],
};
const defaultDeepDiveSectionHeaderContent = {
  title: 'More Autonomous Ads',
  subtitle: 'Helpful resources',
  description:
    `Articles, posts, and deep-dives to help you understand, improve, and plan your campaign. So dig in and see what we're all about.`,
};
export default function DeepDiveContent({deepDiveContent}) {
  const deepContent =
    typeof deepDiveContent !== 'undefined' && !!deepDiveContent
      ? deepDiveContent
      : {...defaultDeepDiveContent, ...defaultDeepDiveSectionHeaderContent};
  return (
    <div className="relative bg-gray-50 py-16 sm:py-24 lg:py-32">
      <div className="relative">
        <DeepDiveContentSectionHeader {...deepContent} />
        <div className="mt-12 mx-auto max-w-md px-4 grid gap-8 sm:max-w-lg sm:px-6 lg:px-8 lg:grid-cols-3 lg:max-w-7xl">
          {deepContent?.contentCards.map((content, index) => (
            <DeepDiveContentCard {...content} key={index} />
          ))}
        </div>
      </div>
    </div>
  );
}
