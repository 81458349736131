import LazyLoad from 'react-lazyload';
import Image from 'next/image';

export default function DeepDiveContentCard(props) {
  const {
    subtitle,
    title,
    textContent,
    authorName,
    authorAlt,
    authorImgSrc,
    imgSrc,
    imgAlt,
    date,
    readingTimeText,
    href,
    id,
  } = props;
  return (
    <div
      id={id}
      className="transform hover:scale-105 flex flex-col rounded-lg shadow-lg overflow-hidden">
      <div className="flex-shrink-0">
        <a href={href}>
          <span className="sr-only">{imgAlt} </span>
          <LazyLoad height={250} once offset={100}>
            <Image
              className="h-48 w-full object-cover"
              src={imgSrc}
              alt={imgAlt}
              height={1000}
              width={2000}
            />
          </LazyLoad>
        </a>
      </div>
      <div className="flex-1 bg-white p-6 flex flex-col justify-between">
        <div className="flex-1">
          <p className="text-sm font-medium text-cyan-600">
            <a href={href} className="hover:underline">
              {subtitle}{' '}
            </a>
          </p>
          <a href={href} className="block mt-2">
            <p className="text-xl font-semibold text-gray-900">{title}</p>
            <p className="mt-3 text-base text-gray-700">
              <span className="tracking-tighter text-sm">{textContent}</span>
            </p>
          </a>
        </div>
        <div className="mt-6 flex items-center">
          <div className="flex-shrink-0">
            <a href="https://www.musicfox.io/team">
              <span className="sr-only">{authorAlt}</span>
              <Image
                height={60}
                width={60}
                className="h-10 w-10 rounded-full"
                src={authorImgSrc}
                alt={authorAlt}
              />
            </a>
          </div>
          <div className="ml-3">
            <p className="text-sm font-medium text-gray-900">
              <a href="https://musicfox.io/team" className="hover:underline">
                {authorName}
              </a>
            </p>
            <div className="flex space-x-1 text-sm text-gray-500">
              <time dateTime="2020-07-03">{date}</time>
              <span aria-hidden="true">·</span>
              <span>{readingTimeText}</span>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
}
