export default function DeepDiveContentSectionHeader({
  title,
  subtitle,
  description,
}) {
  return (
    <div className="text-center mx-auto max-w-md px-4 sm:max-w-3xl sm:px-6 lg:px-8 lg:max-w-7xl">
      <h2 className="text-base font-semibold tracking-wider text-cyan-600 uppercase">
        {title}
      </h2>
      <p className="mt-2 text-3xl font-extrabold text-gray-900 tracking-tight sm:text-4xl">
        {subtitle}
      </p>
      <p className="mt-5 mx-auto max-w-prose text-xl text-gray-500">
        {description}
      </p>
    </div>
  );
}
